import React from "react";
import Scrollchor from 'react-scrollchor';
import Section from "../atoms/section";
import { Parallax } from "react-scroll-parallax";

const Team = ({ data }) => (
  <Section id="team" className="section-team" full noPadding color="yellow">
    <div className="team">
      <Parallax className="team__content text-center" y={["-48px", "32px"]}>
        <h3>{data.title}</h3>
        <p>{data.content}</p>

        <Scrollchor animate={{offset: 0, duration: 1000 }} className="team__content__link cc_link cc_link--white" to="#contact">
          <span>Nous contacter</span>
        </Scrollchor>
      </Parallax>
      <div className="team__image">
        <img src={data.image.url} />
      </div>
    </div>
  </Section>
)

export default Team;
