import React from "react";

import Section from "../atoms/section";

const renderBlocs = (values) => {
  return values.map((value, index) => 
    <div key={index} className="values__block">
      <h5 className="square_title">
        <span>{value.valueTitle}</span>
      </h5>
      <p>{value.valueContent}</p>
    </div>
  );
}

const Values = ({ data }) => (
  <Section id="values" className="values" color="blue">
    <div className="values__title">
      <h3>{data.title}</h3>
    </div>
    <div className="values__blocks">
      { renderBlocs(data.values) }
    </div>
  </Section>
)

export default Values;
