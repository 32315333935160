import React from "react";
import { Parallax } from 'react-scroll-parallax';
import Reveal from 'react-reveal/Reveal';
import Section from "../atoms/section";

const renderPersonas = (personas) => {
  return personas.map((persona, index) => 
    <a href={persona.linkDocument.url} target="_blank" key={'persona-'+index} className="persona" style={{'backgroundImage': 'url(' + persona.backgroundImage.url + ')'}}>
      <Parallax y={["-24px", "0px"]}>
        <h3 className="text-white">
          <span>{persona.title}</span>
        </h3>
        <div className="cc_link">
          <span className="text-white">{persona.linkText}</span>
        </div>
      </Parallax>
    </a>
  );
}

const Personas = ({ data }) => (
  <Section id="personas" className="section-personas" color="black">
    <Reveal 
      delay={500}
      duration={1000}
      effect={`appear_personas`}>
      <div className="personas__wrapper">
        { renderPersonas(data.personas) }
      </div>
    </Reveal>
  </Section>
)

export default Personas;
