import React from "react";
import Slider from "react-slick";
import { Parallax } from 'react-scroll-parallax';
import Section from "../atoms/section";

// Assets
import QuotesSvg from '../../images/quotes.svg';

const renderActivities = (items) => {
  return items.map((item, index) =>
    <div key={"item_"+ index} className="activity_item">
      <div className="activity_item__jauge" style={{'width': item.pourcent + '%'}}></div>
      <p>{item.content}</p>
    </div>
  );
}

const renderFigures = (figures) => {
  return figures.map((figure, index) =>
    <div key={index} className="figure_item">
      <Parallax y={[((figures.length - index) * -10) + 'px', "0px"]}>
        <span>{figure.figureNumber}</span>
        <h5>{figure.figureText}</h5>
      </Parallax>
    </div>
  );
}

const settings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const renderTestimonials = (testimonials) => {
  return (
    <Slider {...settings}>
      {
        testimonials.map((testimonial, index) => {
          return <div key={'testimonial_'+index} className="testimonial text-center">
            <blockquote>{testimonial.testimonialContent}</blockquote>
            <span>{testimonial.testimonialAuthor}</span>
          </div>
        })
      }
    </Slider>
  )
}


const Activity = ({ data }) => (
  <Section id="activity" className="activity">
    <div className="activity__title">
      <h3>{data.title}</h3>
    </div>

    <div className="activity__items">{ renderActivities(data.activityItems) }</div>

    <div className="activity__figures">{ renderFigures(data.figures) }</div>

    <div className="activity__testimonials">
      <img className="activity__testimonials__bgQuote" src={QuotesSvg} alt="" draggable="false" />
      { renderTestimonials(data.testimonials) }
    </div>
  </Section>
)

export default Activity;
