import React from "react";
import Section from "../atoms/section";

const Contact = ({ data }) => (
  <Section id="contact" className="contact" full noPadding color="blue">
    <div className="contact_wrapper" style={{'backgroundImage': 'url(' + data.backgroundImage.url + ')'}}>
      <div className="contact__content text-center">
        <h5 className="text-white">{data.title}</h5>
        <a className="cc_link cc_link--big" href={'mailto:'+data.email}><h3 className="text-white"><span>{data.email}</span></h3></a>
        <p className="text-white">{data.phoneNumber}</p>
      </div>
    </div>
  </Section>
)

export default Contact;
