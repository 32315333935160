import React from 'react'

const Section = ({ children, color, noPadding, full, className, id }) => (
  <section id={id} className={`section ${className ? className : ""} ${color ? "section--"+color : ""} ${noPadding ? "section--nopadding" : ""}`}>
    <div className={full ? "section__wrapper--full": "section__wrapper"}>
      {children}
    </div>
  </section>
)

export default Section;

