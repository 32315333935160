import React from "react";
import ModalVideo from 'react-modal-video';
import { Parallax } from 'react-scroll-parallax';

import Section from "../atoms/section";

export default class Hero extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render () {
    const {data} = this.props;

    return (
      <>
        <ModalVideo 
          channel={data.urlVidO.provider} 
          isOpen={this.state.isOpen} 
          videoId={data.urlVidO.providerUid} 
          youtube={{ modestbranding:true, controls: 0, autoplay: 1 }} 
          onClose={() => this.setState({isOpen: false})} 
        />
        <Section className="hero">
          <div className="hero__content">
            <h1>
              <span className="underline__big__blue nohover">{data.heroTitle}</span>
            </h1>
            <h2>{data.heroSubtitle}</h2>

            { data.buttonText ? 
              <button className="cc_button" onClick={this.openModal}>{data.buttonText}</button> 
              : null
            }
            
          </div>
        </Section>

        <Section full noPadding>
          <div className="hero__image">
            <img src={data.heroImage.url} />
          </div>
        </Section>
      </>
    )
  }
}
