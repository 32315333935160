import React from "react"
import Helmet from 'react-helmet'
import { HelmetDatoCms } from 'gatsby-source-datocms'
//import { Link } from "gatsby"

import Layout from "../layouts"

import { 
  Hero, 
  Methodes, 
  Values, 
  Team,
  Activity,
  Personas,
  Contact
} from "../components/sections/";

const IndexPage = ({data}) => (
  <Layout>
    
    <Helmet
      title={data.datoCmsSite.globalSeo.siteName}
      meta={[
        { name: 'author', content: 'Jean-Charles Deglesne' },
        { name: 'publisher', content: 'Cap Conseil' },
        { name: 'description', content: data.datoCmsSite.globalSeo.fallbackSeo.description },
        { name: 'format-detection', content: 'telephone=no'},

        { property: 'og:image', content: data.datoCmsSite.globalSeo.fallbackSeo.image.url },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: data.datoCmsSite.globalSeo.siteName },
        { property: 'og:title', content: data.datoCmsSite.globalSeo.siteName },
        { property: 'og:description', content: data.datoCmsSite.globalSeo.fallbackSeo.description }
      ]}
    />
    <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />

    <Hero data={data.datoCmsHero} />
    <Methodes data={data.datoCmsMethode} />
    <Values data={data.datoCmsValue} />
    <Team data={data.datoCmsEquipe} />
    <Activity data={data.datoCmsActivity} />
    <Personas data={data.datoCmsPersona} />
    <Contact data={data.datoCmsContact} />

  </Layout>
)

export default IndexPage;

export const query = graphql`
  query IndexQuey {
    datoCmsSite {
      globalSeo {
        siteName
        fallbackSeo {
          description
          image {
            url
          }
        }
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsHero {
      heroTitle
      heroSubtitle
      heroImage {
        id
        url
      }
      buttonText
      urlVidO {
        url
        title
        provider
        providerUid
        thumbnailUrl
        width
        height
      }
    }
    datoCmsMethode {
      title
      blocs {
        id
        title
        subtitle
        content
        image {
          id
          url
        }
        backgroundColor {
          hex
        }
        linkText
        linkDocument {
          url
        }
      }
    }
    datoCmsValue {
      title
      values {
        valueTitle
        valueContent
      }
    }
    datoCmsEquipe {
      title
      content
      image {
        id
        url
      }
    }
    datoCmsActivity {
      title
      activityItems {
        content
        pourcent
      }
      figures {
        figureText
        figureNumber
      }
      testimonials {
        testimonialAuthor
        testimonialContent
      }
    }
    datoCmsPersona {
      personas {
        title
        linkText
        linkDocument {
          url
        }
        backgroundImage {
          url
        }
      }
    }
    datoCmsContact {
      title
      email
      phoneNumber
      backgroundImage {
        url
      }
    }
  }
`
