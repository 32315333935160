import React from "react";
import Reveal from 'react-reveal/Reveal';
import { Parallax } from 'react-scroll-parallax';

import Section from "../atoms/section";

const renderBlocs = (blocs) => {
  return blocs.map((bloc, index) => 
    <div key={index} className={`methodes__block methodes__block--` + (index+1)}>
      <div className="methode__image">
        <Reveal 
          delay={250}
          effect={`rotate_block--` + (index+1)}>
          <div className="methode__image__background__inner" style={{'backgroundColor': bloc.backgroundColor.hex}}></div>  
        </Reveal>
        <img src={bloc.image.url} />
      </div>

      <Parallax className="methode__content" y={["-32px", "32px"]}>
        <h5 className="hypen_title">{bloc.title}</h5>
        <h4>{bloc.subtitle}</h4>
        <div className="methode__content__items">
          <p>{bloc.content}</p>
        </div>

        { bloc.linkText ?
          <a className="cc_link" href={bloc.linkDocument.url} target="_blank">
            <span>{bloc.linkText}</span>
          </a> : null
        }
      </Parallax>
    </div>
  );
}

const Methodes = ({ data }) => (
  <Section className="methodes" id="methodes">
    <div className="methodes__title">
      <h3>{data.title}</h3>
    </div>
    {
      renderBlocs(data.blocs)
    }
  </Section>
)

export default Methodes;
